.window {
  box-sizing: border-box;
  pointer-events: none;
  filter: drop-shadow(0 0 10px #fff);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.snow {
  background: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
}

.snow:first-child {
  opacity: .1364;
  animation: 27s linear -23s infinite fall-1;
  transform: translate(82.2313vw, -10px)scale(.7863);
}

@keyframes fall-1 {
  65.343% {
    transform: translate(77.7881vw, 65.343vh)scale(.7863);
  }

  to {
    transform: translate(80.0097vw, 100vh)scale(.7863);
  }
}

.snow:nth-child(2) {
  opacity: .9386;
  animation: 20s linear -6s infinite fall-2;
  transform: translate(44.4761vw, -10px)scale(.6909);
}

@keyframes fall-2 {
  31.745% {
    transform: translate(44.9796vw, 31.745vh)scale(.6909);
  }

  to {
    transform: translate(44.7279vw, 100vh)scale(.6909);
  }
}

.snow:nth-child(3) {
  opacity: .4748;
  animation: 26s linear -29s infinite fall-3;
  transform: translate(79.8665vw, -10px)scale(.6873);
}

@keyframes fall-3 {
  50.634% {
    transform: translate(87.2005vw, 50.634vh)scale(.6873);
  }

  to {
    transform: translate(83.5335vw, 100vh)scale(.6873);
  }
}

.snow:nth-child(4) {
  opacity: .3231;
  animation: 10s linear -21s infinite fall-4;
  transform: translate(85.7352vw, -10px)scale(.25);
}

@keyframes fall-4 {
  52.586% {
    transform: translate(85.6265vw, 52.586vh)scale(.25);
  }

  to {
    transform: translate(85.6809vw, 100vh)scale(.25);
  }
}

.snow:nth-child(5) {
  opacity: .2596;
  animation: 13s linear -5s infinite fall-5;
  transform: translate(74.9832vw, -10px)scale(.7264);
}

@keyframes fall-5 {
  59.552% {
    transform: translate(76.7696vw, 59.552vh)scale(.7264);
  }

  to {
    transform: translate(75.8764vw, 100vh)scale(.7264);
  }
}

.snow:nth-child(6) {
  opacity: .8287;
  animation: 14s linear -23s infinite fall-6;
  transform: translate(54.9577vw, -10px)scale(.0013);
}

@keyframes fall-6 {
  38.23% {
    transform: translate(51.9426vw, 38.23vh)scale(.0013);
  }

  to {
    transform: translate(53.4502vw, 100vh)scale(.0013);
  }
}

.snow:nth-child(7) {
  opacity: .2203;
  animation: 17s linear -16s infinite fall-7;
  transform: translate(57.2961vw, -10px)scale(.9432);
}

@keyframes fall-7 {
  72.724% {
    transform: translate(54.335vw, 72.724vh)scale(.9432);
  }

  to {
    transform: translate(55.8156vw, 100vh)scale(.9432);
  }
}

.snow:nth-child(8) {
  opacity: .9562;
  animation: 25s linear -14s infinite fall-8;
  transform: translate(67.1384vw, -10px)scale(.3331);
}

@keyframes fall-8 {
  46.157% {
    transform: translate(71.64vw, 46.157vh)scale(.3331);
  }

  to {
    transform: translate(69.3892vw, 100vh)scale(.3331);
  }
}

.snow:nth-child(9) {
  opacity: .8685;
  animation: 19s linear -12s infinite fall-9;
  transform: translate(28.721vw, -10px)scale(.3167);
}

@keyframes fall-9 {
  56.655% {
    transform: translate(31.6602vw, 56.655vh)scale(.3167);
  }

  to {
    transform: translate(30.1906vw, 100vh)scale(.3167);
  }
}

.snow:nth-child(10) {
  opacity: .6276;
  animation: 28s linear -16s infinite fall-10;
  transform: translate(55.087vw, -10px)scale(.6523);
}

@keyframes fall-10 {
  30.816% {
    transform: translate(54.6646vw, 30.816vh)scale(.6523);
  }

  to {
    transform: translate(54.8758vw, 100vh)scale(.6523);
  }
}

.snow:nth-child(11) {
  opacity: .0696;
  animation: 19s linear -5s infinite fall-11;
  transform: translate(43.0781vw, -10px)scale(.5094);
}

@keyframes fall-11 {
  41.466% {
    transform: translate(51.4085vw, 41.466vh)scale(.5094);
  }

  to {
    transform: translate(47.2433vw, 100vh)scale(.5094);
  }
}

.snow:nth-child(12) {
  opacity: .7283;
  animation: 20s linear -12s infinite fall-12;
  transform: translate(53.3583vw, -10px)scale(.4907);
}

@keyframes fall-12 {
  49.828% {
    transform: translate(57.6372vw, 49.828vh)scale(.4907);
  }

  to {
    transform: translate(55.4978vw, 100vh)scale(.4907);
  }
}

.snow:nth-child(13) {
  opacity: .5806;
  animation: 27s linear -26s infinite fall-13;
  transform: translate(.9218vw, -10px)scale(.8002);
}

@keyframes fall-13 {
  41.57% {
    transform: translate(1.8292vw, 41.57vh)scale(.8002);
  }

  to {
    transform: translate(1.3755vw, 100vh)scale(.8002);
  }
}

.snow:nth-child(14) {
  opacity: .6996;
  animation: 15s linear -3s infinite fall-14;
  transform: translate(77.4436vw, -10px)scale(.9606);
}

@keyframes fall-14 {
  69.225% {
    transform: translate(67.8695vw, 69.225vh)scale(.9606);
  }

  to {
    transform: translate(72.6566vw, 100vh)scale(.9606);
  }
}

.snow:nth-child(15) {
  opacity: .6286;
  animation: 24s linear -7s infinite fall-15;
  transform: translate(51.5396vw, -10px)scale(.1547);
}

@keyframes fall-15 {
  47.05% {
    transform: translate(45.0746vw, 47.05vh)scale(.1547);
  }

  to {
    transform: translate(48.3071vw, 100vh)scale(.1547);
  }
}

.snow:nth-child(16) {
  opacity: .0077;
  animation: 20s linear -17s infinite fall-16;
  transform: translate(74.9862vw, -10px)scale(.242);
}

@keyframes fall-16 {
  47.264% {
    transform: translate(69.739vw, 47.264vh)scale(.242);
  }

  to {
    transform: translate(72.3626vw, 100vh)scale(.242);
  }
}

.snow:nth-child(17) {
  opacity: .4839;
  animation: 15s linear -28s infinite fall-17;
  transform: translate(97.9206vw, -10px)scale(.1405);
}

@keyframes fall-17 {
  44.106% {
    transform: translate(89.9019vw, 44.106vh)scale(.1405);
  }

  to {
    transform: translate(93.9113vw, 100vh)scale(.1405);
  }
}

.snow:nth-child(18) {
  opacity: .5348;
  animation: 26s linear -17s infinite fall-18;
  transform: translate(4.0595vw, -10px)scale(.9888);
}

@keyframes fall-18 {
  77.746% {
    transform: translate(10.7408vw, 77.746vh)scale(.9888);
  }

  to {
    transform: translate(7.40015vw, 100vh)scale(.9888);
  }
}

.snow:nth-child(19) {
  opacity: .532;
  animation: 30s linear -7s infinite fall-19;
  transform: translate(50.515vw, -10px)scale(.2119);
}

@keyframes fall-19 {
  33.864% {
    transform: translate(40.7821vw, 33.864vh)scale(.2119);
  }

  to {
    transform: translate(45.6486vw, 100vh)scale(.2119);
  }
}

.snow:nth-child(20) {
  opacity: .2424;
  animation: 13s linear -24s infinite fall-20;
  transform: translate(22.7855vw, -10px)scale(.7439);
}

@keyframes fall-20 {
  63.772% {
    transform: translate(14.5357vw, 63.772vh)scale(.7439);
  }

  to {
    transform: translate(18.6606vw, 100vh)scale(.7439);
  }
}

.snow:nth-child(21) {
  opacity: .5138;
  animation: 18s linear -17s infinite fall-21;
  transform: translate(22.3094vw, -10px)scale(.9864);
}

@keyframes fall-21 {
  64.084% {
    transform: translate(19.8423vw, 64.084vh)scale(.9864);
  }

  to {
    transform: translate(21.0759vw, 100vh)scale(.9864);
  }
}

.snow:nth-child(22) {
  opacity: .0332;
  animation: 24s linear -16s infinite fall-22;
  transform: translate(67.0775vw, -10px)scale(.2716);
}

@keyframes fall-22 {
  54.055% {
    transform: translate(71.8874vw, 54.055vh)scale(.2716);
  }

  to {
    transform: translate(69.4825vw, 100vh)scale(.2716);
  }
}

.snow:nth-child(23) {
  opacity: .1538;
  animation: 22s linear -17s infinite fall-23;
  transform: translate(52.855vw, -10px)scale(.2065);
}

@keyframes fall-23 {
  63.105% {
    transform: translate(56.5312vw, 63.105vh)scale(.2065);
  }

  to {
    transform: translate(54.6931vw, 100vh)scale(.2065);
  }
}

.snow:nth-child(24) {
  opacity: .027;
  animation: 22s linear -14s infinite fall-24;
  transform: translate(39.1657vw, -10px)scale(.6495);
}

@keyframes fall-24 {
  35.992% {
    transform: translate(42.5091vw, 35.992vh)scale(.6495);
  }

  to {
    transform: translate(40.8374vw, 100vh)scale(.6495);
  }
}

.snow:nth-child(25) {
  opacity: .4479;
  animation: 20s linear -14s infinite fall-25;
  transform: translate(92.5964vw, -10px)scale(.477);
}

@keyframes fall-25 {
  42.067% {
    transform: translate(83.6051vw, 42.067vh)scale(.477);
  }

  to {
    transform: translate(88.1008vw, 100vh)scale(.477);
  }
}

.snow:nth-child(26) {
  opacity: .0631;
  animation: 10s linear -10s infinite fall-26;
  transform: translate(68.0214vw, -10px)scale(.0277);
}

@keyframes fall-26 {
  72.599% {
    transform: translate(72.9676vw, 72.599vh)scale(.0277);
  }

  to {
    transform: translate(70.4945vw, 100vh)scale(.0277);
  }
}

.snow:nth-child(27) {
  opacity: .5707;
  animation: 22s linear -12s infinite fall-27;
  transform: translate(24.6851vw, -10px)scale(.6735);
}

@keyframes fall-27 {
  65.978% {
    transform: translate(27.554vw, 65.978vh)scale(.6735);
  }

  to {
    transform: translate(26.1196vw, 100vh)scale(.6735);
  }
}

.snow:nth-child(28) {
  opacity: .3657;
  animation: 19s linear -9s infinite fall-28;
  transform: translate(12.4139vw, -10px)scale(.3656);
}

@keyframes fall-28 {
  59.857% {
    transform: translate(3.1087vw, 59.857vh)scale(.3656);
  }

  to {
    transform: translate(7.7613vw, 100vh)scale(.3656);
  }
}

.snow:nth-child(29) {
  opacity: .9101;
  animation: 13s linear -16s infinite fall-29;
  transform: translate(68.525vw, -10px)scale(.9472);
}

@keyframes fall-29 {
  33.3% {
    transform: translate(64.9843vw, 33.3vh)scale(.9472);
  }

  to {
    transform: translate(66.7547vw, 100vh)scale(.9472);
  }
}

.snow:nth-child(30) {
  opacity: .9642;
  animation: 28s linear -22s infinite fall-30;
  transform: translate(28.647vw, -10px)scale(.988);
}

@keyframes fall-30 {
  54.794% {
    transform: translate(26.5669vw, 54.794vh)scale(.988);
  }

  to {
    transform: translate(27.607vw, 100vh)scale(.988);
  }
}

.snow:nth-child(31) {
  opacity: .9683;
  animation: 15s linear -18s infinite fall-31;
  transform: translate(56.569vw, -10px)scale(.3002);
}

@keyframes fall-31 {
  41.279% {
    transform: translate(51.1001vw, 41.279vh)scale(.3002);
  }

  to {
    transform: translate(53.8346vw, 100vh)scale(.3002);
  }
}

.snow:nth-child(32) {
  opacity: .6691;
  animation: 25s linear -15s infinite fall-32;
  transform: translate(84.6135vw, -10px)scale(.1281);
}

@keyframes fall-32 {
  66.544% {
    transform: translate(81.4651vw, 66.544vh)scale(.1281);
  }

  to {
    transform: translate(83.0393vw, 100vh)scale(.1281);
  }
}

.snow:nth-child(33) {
  opacity: .1674;
  animation: 28s linear -15s infinite fall-33;
  transform: translate(78.3289vw, -10px)scale(.5307);
}

@keyframes fall-33 {
  42.94% {
    transform: translate(73.1029vw, 42.94vh)scale(.5307);
  }

  to {
    transform: translate(75.7159vw, 100vh)scale(.5307);
  }
}

.snow:nth-child(34) {
  opacity: .5063;
  animation: 27s linear -18s infinite fall-34;
  transform: translate(46.5136vw, -10px)scale(.8248);
}

@keyframes fall-34 {
  73.205% {
    transform: translate(50.114vw, 73.205vh)scale(.8248);
  }

  to {
    transform: translate(48.3138vw, 100vh)scale(.8248);
  }
}

.snow:nth-child(35) {
  opacity: .6192;
  animation: 20s linear -10s infinite fall-35;
  transform: translate(13.7004vw, -10px)scale(.9279);
}

@keyframes fall-35 {
  41.022% {
    transform: translate(8.5521vw, 41.022vh)scale(.9279);
  }

  to {
    transform: translate(11.1263vw, 100vh)scale(.9279);
  }
}

.snow:nth-child(36) {
  opacity: .9667;
  animation: 10s linear -25s infinite fall-36;
  transform: translate(94.0611vw, -10px)scale(.6602);
}

@keyframes fall-36 {
  53.511% {
    transform: translate(100.788vw, 53.511vh)scale(.6602);
  }

  to {
    transform: translate(97.4245vw, 100vh)scale(.6602);
  }
}

.snow:nth-child(37) {
  opacity: .9981;
  animation: 23s linear -13s infinite fall-37;
  transform: translate(87.6034vw, -10px)scale(.1878);
}

@keyframes fall-37 {
  79.2% {
    transform: translate(93.4931vw, 79.2vh)scale(.1878);
  }

  to {
    transform: translate(90.5483vw, 100vh)scale(.1878);
  }
}

.snow:nth-child(38) {
  opacity: .9242;
  animation: 30s linear -29s infinite fall-38;
  transform: translate(45.7763vw, -10px)scale(.4504);
}

@keyframes fall-38 {
  49.734% {
    transform: translate(36.0349vw, 49.734vh)scale(.4504);
  }

  to {
    transform: translate(40.9056vw, 100vh)scale(.4504);
  }
}

.snow:nth-child(39) {
  opacity: .0362;
  animation: 25s linear -21s infinite fall-39;
  transform: translate(22.334vw, -10px)scale(.9909);
}

@keyframes fall-39 {
  42.814% {
    transform: translate(21.2204vw, 42.814vh)scale(.9909);
  }

  to {
    transform: translate(21.7772vw, 100vh)scale(.9909);
  }
}

.snow:nth-child(40) {
  opacity: .8963;
  animation: 12s linear -25s infinite fall-40;
  transform: translate(72.7826vw, -10px)scale(.5039);
}

@keyframes fall-40 {
  62.162% {
    transform: translate(67.337vw, 62.162vh)scale(.5039);
  }

  to {
    transform: translate(70.0598vw, 100vh)scale(.5039);
  }
}

.snow:nth-child(41) {
  opacity: .5631;
  animation: 30s linear -20s infinite fall-41;
  transform: translate(45.4899vw, -10px)scale(.3321);
}

@keyframes fall-41 {
  69.146% {
    transform: translate(52.8542vw, 69.146vh)scale(.3321);
  }

  to {
    transform: translate(49.1721vw, 100vh)scale(.3321);
  }
}

.snow:nth-child(42) {
  opacity: .9806;
  animation: 28s linear -18s infinite fall-42;
  transform: translate(73.7428vw, -10px)scale(.8504);
}

@keyframes fall-42 {
  61.58% {
    transform: translate(73.4289vw, 61.58vh)scale(.8504);
  }

  to {
    transform: translate(73.5859vw, 100vh)scale(.8504);
  }
}

.snow:nth-child(43) {
  opacity: .9344;
  animation: 19s linear -29s infinite fall-43;
  transform: translate(59.3926vw, -10px)scale(.6066);
}

@keyframes fall-43 {
  76.361% {
    transform: translate(56.0164vw, 76.361vh)scale(.6066);
  }

  to {
    transform: translate(57.7045vw, 100vh)scale(.6066);
  }
}

.snow:nth-child(44) {
  opacity: .2989;
  animation: 27s linear -23s infinite fall-44;
  transform: translate(79.6133vw, -10px)scale(.1864);
}

@keyframes fall-44 {
  59.591% {
    transform: translate(84.4865vw, 59.591vh)scale(.1864);
  }

  to {
    transform: translate(82.0499vw, 100vh)scale(.1864);
  }
}

.snow:nth-child(45) {
  opacity: .5673;
  animation: 24s linear -13s infinite fall-45;
  transform: translate(93.594vw, -10px)scale(.4942);
}

@keyframes fall-45 {
  75.672% {
    transform: translate(101.668vw, 75.672vh)scale(.4942);
  }

  to {
    transform: translate(97.6308vw, 100vh)scale(.4942);
  }
}

.snow:nth-child(46) {
  opacity: .3112;
  animation: 17s linear -8s infinite fall-46;
  transform: translate(64.873vw, -10px)scale(.1594);
}

@keyframes fall-46 {
  65.808% {
    transform: translate(66.1932vw, 65.808vh)scale(.1594);
  }

  to {
    transform: translate(65.5331vw, 100vh)scale(.1594);
  }
}

.snow:nth-child(47) {
  opacity: .3674;
  animation: 24s linear -8s infinite fall-47;
  transform: translate(69.2192vw, -10px)scale(.0137);
}

@keyframes fall-47 {
  70.781% {
    transform: translate(77.8559vw, 70.781vh)scale(.0137);
  }

  to {
    transform: translate(73.5376vw, 100vh)scale(.0137);
  }
}

.snow:nth-child(48) {
  opacity: .0688;
  animation: 23s linear -16s infinite fall-48;
  transform: translate(62.1856vw, -10px)scale(.6748);
}

@keyframes fall-48 {
  59.884% {
    transform: translate(67.8157vw, 59.884vh)scale(.6748);
  }

  to {
    transform: translate(65.0007vw, 100vh)scale(.6748);
  }
}

.snow:nth-child(49) {
  opacity: .4961;
  animation: 14s linear -8s infinite fall-49;
  transform: translate(71.4706vw, -10px)scale(.1354);
}

@keyframes fall-49 {
  67.61% {
    transform: translate(65.2686vw, 67.61vh)scale(.1354);
  }

  to {
    transform: translate(68.3696vw, 100vh)scale(.1354);
  }
}

.snow:nth-child(50) {
  opacity: .238;
  animation: 28s linear -10s infinite fall-50;
  transform: translate(5.4802vw, -10px)scale(.274);
}

@keyframes fall-50 {
  31.695% {
    transform: translate(7.6815vw, 31.695vh)scale(.274);
  }

  to {
    transform: translate(6.58085vw, 100vh)scale(.274);
  }
}

.snow:nth-child(51) {
  opacity: .461;
  animation: 15s linear -2s infinite fall-51;
  transform: translate(31.906vw, -10px)scale(.3547);
}

@keyframes fall-51 {
  55.343% {
    transform: translate(23.1549vw, 55.343vh)scale(.3547);
  }

  to {
    transform: translate(27.5305vw, 100vh)scale(.3547);
  }
}

.snow:nth-child(52) {
  opacity: .1583;
  animation: 18s linear -22s infinite fall-52;
  transform: translate(96.9088vw, -10px)scale(.4426);
}

@keyframes fall-52 {
  40.192% {
    transform: translate(90.7059vw, 40.192vh)scale(.4426);
  }

  to {
    transform: translate(93.8074vw, 100vh)scale(.4426);
  }
}

.snow:nth-child(53) {
  opacity: .7749;
  animation: 14s linear -7s infinite fall-53;
  transform: translate(57.5278vw, -10px)scale(.6148);
}

@keyframes fall-53 {
  68.588% {
    transform: translate(60.7395vw, 68.588vh)scale(.6148);
  }

  to {
    transform: translate(59.1337vw, 100vh)scale(.6148);
  }
}

.snow:nth-child(54) {
  opacity: .2784;
  animation: 28s linear -26s infinite fall-54;
  transform: translate(72.9829vw, -10px)scale(.2507);
}

@keyframes fall-54 {
  58.416% {
    transform: translate(82.0015vw, 58.416vh)scale(.2507);
  }

  to {
    transform: translate(77.4922vw, 100vh)scale(.2507);
  }
}

.snow:nth-child(55) {
  opacity: .9243;
  animation: 18s linear -4s infinite fall-55;
  transform: translate(13.0774vw, -10px)scale(.5496);
}

@keyframes fall-55 {
  52.599% {
    transform: translate(8.0595vw, 52.599vh)scale(.5496);
  }

  to {
    transform: translate(10.5685vw, 100vh)scale(.5496);
  }
}

.snow:nth-child(56) {
  opacity: .0811;
  animation: 18s linear -29s infinite fall-56;
  transform: translate(58.9802vw, -10px)scale(.173);
}

@keyframes fall-56 {
  41.587% {
    transform: translate(57.5694vw, 41.587vh)scale(.173);
  }

  to {
    transform: translate(58.2748vw, 100vh)scale(.173);
  }
}

.snow:nth-child(57) {
  opacity: .9797;
  animation: 18s linear -27s infinite fall-57;
  transform: translate(25.0391vw, -10px)scale(.7312);
}

@keyframes fall-57 {
  73.038% {
    transform: translate(26.4578vw, 73.038vh)scale(.7312);
  }

  to {
    transform: translate(25.7485vw, 100vh)scale(.7312);
  }
}

.snow:nth-child(58) {
  opacity: .5929;
  animation: 25s linear -4s infinite fall-58;
  transform: translate(42.327vw, -10px)scale(.1337);
}

@keyframes fall-58 {
  63.433% {
    transform: translate(40.6617vw, 63.433vh)scale(.1337);
  }

  to {
    transform: translate(41.4944vw, 100vh)scale(.1337);
  }
}

.snow:nth-child(59) {
  opacity: .4289;
  animation: 15s linear -27s infinite fall-59;
  transform: translate(15.2247vw, -10px)scale(.1851);
}

@keyframes fall-59 {
  70.534% {
    transform: translate(14.3557vw, 70.534vh)scale(.1851);
  }

  to {
    transform: translate(14.7902vw, 100vh)scale(.1851);
  }
}

.snow:nth-child(60) {
  opacity: .6795;
  animation: 11s linear -30s infinite fall-60;
  transform: translate(17.9368vw, -10px)scale(.24);
}

@keyframes fall-60 {
  38.126% {
    transform: translate(24.3168vw, 38.126vh)scale(.24);
  }

  to {
    transform: translate(21.1268vw, 100vh)scale(.24);
  }
}

.snow:nth-child(61) {
  opacity: .1039;
  animation: 29s linear -14s infinite fall-61;
  transform: translate(29.7802vw, -10px)scale(.7182);
}

@keyframes fall-61 {
  58.6% {
    transform: translate(26.4215vw, 58.6vh)scale(.7182);
  }

  to {
    transform: translate(28.1009vw, 100vh)scale(.7182);
  }
}

.snow:nth-child(62) {
  opacity: .9957;
  animation: 24s linear -21s infinite fall-62;
  transform: translate(4.0348vw, -10px)scale(.3624);
}

@keyframes fall-62 {
  40.429% {
    transform: translate(9.8011vw, 40.429vh)scale(.3624);
  }

  to {
    transform: translate(6.91795vw, 100vh)scale(.3624);
  }
}

.snow:nth-child(63) {
  opacity: .6313;
  animation: 11s linear -6s infinite fall-63;
  transform: translate(6.556vw, -10px)scale(.031);
}

@keyframes fall-63 {
  44.836% {
    transform: translate(7.8627vw, 44.836vh)scale(.031);
  }

  to {
    transform: translate(7.20935vw, 100vh)scale(.031);
  }
}

.snow:nth-child(64) {
  opacity: .4424;
  animation: 18s linear -19s infinite fall-64;
  transform: translate(46.3978vw, -10px)scale(.5924);
}

@keyframes fall-64 {
  51.924% {
    transform: translate(40.6746vw, 51.924vh)scale(.5924);
  }

  to {
    transform: translate(43.5362vw, 100vh)scale(.5924);
  }
}

.snow:nth-child(65) {
  opacity: .5703;
  animation: 27s linear -7s infinite fall-65;
  transform: translate(64.2431vw, -10px)scale(.8898);
}

@keyframes fall-65 {
  30.355% {
    transform: translate(63.1203vw, 30.355vh)scale(.8898);
  }

  to {
    transform: translate(63.6817vw, 100vh)scale(.8898);
  }
}

.snow:nth-child(66) {
  opacity: .1838;
  animation: 13s linear -15s infinite fall-66;
  transform: translate(20.3338vw, -10px)scale(.5161);
}

@keyframes fall-66 {
  60.11% {
    transform: translate(15.0389vw, 60.11vh)scale(.5161);
  }

  to {
    transform: translate(17.6864vw, 100vh)scale(.5161);
  }
}

.snow:nth-child(67) {
  opacity: .1145;
  animation: 26s linear -28s infinite fall-67;
  transform: translate(12.9544vw, -10px)scale(.6976);
}

@keyframes fall-67 {
  69.34% {
    transform: translate(16.9065vw, 69.34vh)scale(.6976);
  }

  to {
    transform: translate(14.9305vw, 100vh)scale(.6976);
  }
}

.snow:nth-child(68) {
  opacity: .6725;
  animation: 22s linear -19s infinite fall-68;
  transform: translate(56.8905vw, -10px)scale(.0916);
}

@keyframes fall-68 {
  65.687% {
    transform: translate(48.6267vw, 65.687vh)scale(.0916);
  }

  to {
    transform: translate(52.7586vw, 100vh)scale(.0916);
  }
}

.snow:nth-child(69) {
  opacity: .461;
  animation: 25s linear -28s infinite fall-69;
  transform: translate(96.7856vw, -10px)scale(.9072);
}

@keyframes fall-69 {
  54.093% {
    transform: translate(105.47vw, 54.093vh)scale(.9072);
  }

  to {
    transform: translate(101.128vw, 100vh)scale(.9072);
  }
}

.snow:nth-child(70) {
  opacity: .9748;
  animation: 15s linear -30s infinite fall-70;
  transform: translate(62.5139vw, -10px)scale(.7016);
}

@keyframes fall-70 {
  34.218% {
    transform: translate(68.6511vw, 34.218vh)scale(.7016);
  }

  to {
    transform: translate(65.5825vw, 100vh)scale(.7016);
  }
}

.snow:nth-child(71) {
  opacity: .5647;
  animation: 10s linear -18s infinite fall-71;
  transform: translate(78.7795vw, -10px)scale(.7623);
}

@keyframes fall-71 {
  42.582% {
    transform: translate(80.8839vw, 42.582vh)scale(.7623);
  }

  to {
    transform: translate(79.8317vw, 100vh)scale(.7623);
  }
}

.snow:nth-child(72) {
  opacity: .4184;
  animation: 28s linear -30s infinite fall-72;
  transform: translate(43.251vw, -10px)scale(.2464);
}

@keyframes fall-72 {
  33.377% {
    transform: translate(48.9489vw, 33.377vh)scale(.2464);
  }

  to {
    transform: translate(46.1vw, 100vh)scale(.2464);
  }
}

.snow:nth-child(73) {
  opacity: .6056;
  animation: 11s linear -4s infinite fall-73;
  transform: translate(23.2616vw, -10px)scale(.4242);
}

@keyframes fall-73 {
  39.524% {
    transform: translate(30.5329vw, 39.524vh)scale(.4242);
  }

  to {
    transform: translate(26.8973vw, 100vh)scale(.4242);
  }
}

.snow:nth-child(74) {
  opacity: .6702;
  animation: 21s linear -13s infinite fall-74;
  transform: translate(29.1476vw, -10px)scale(.2822);
}

@keyframes fall-74 {
  79.46% {
    transform: translate(28.1258vw, 79.46vh)scale(.2822);
  }

  to {
    transform: translate(28.6367vw, 100vh)scale(.2822);
  }
}

.snow:nth-child(75) {
  opacity: .4335;
  animation: 24s linear -10s infinite fall-75;
  transform: translate(21.7903vw, -10px)scale(.9176);
}

@keyframes fall-75 {
  41.978% {
    transform: translate(13.9585vw, 41.978vh)scale(.9176);
  }

  to {
    transform: translate(17.8744vw, 100vh)scale(.9176);
  }
}

.snow:nth-child(76) {
  opacity: .8579;
  animation: 25s linear -20s infinite fall-76;
  transform: translate(99.4637vw, -10px)scale(.0502);
}

@keyframes fall-76 {
  48.448% {
    transform: translate(102.592vw, 48.448vh)scale(.0502);
  }

  to {
    transform: translate(101.028vw, 100vh)scale(.0502);
  }
}

.snow:nth-child(77) {
  opacity: .2218;
  animation: 23s linear -16s infinite fall-77;
  transform: translate(65.6886vw, -10px)scale(.9941);
}

@keyframes fall-77 {
  49.363% {
    transform: translate(63.0789vw, 49.363vh)scale(.9941);
  }

  to {
    transform: translate(64.3838vw, 100vh)scale(.9941);
  }
}

.snow:nth-child(78) {
  opacity: .4406;
  animation: 15s linear -22s infinite fall-78;
  transform: translate(6.5309vw, -10px)scale(.5314);
}

@keyframes fall-78 {
  73.18% {
    transform: translate(8.3066vw, 73.18vh)scale(.5314);
  }

  to {
    transform: translate(7.41875vw, 100vh)scale(.5314);
  }
}

.snow:nth-child(79) {
  opacity: .5745;
  animation: 26s linear -14s infinite fall-79;
  transform: translate(55.6052vw, -10px)scale(.4614);
}

@keyframes fall-79 {
  64.742% {
    transform: translate(62.5246vw, 64.742vh)scale(.4614);
  }

  to {
    transform: translate(59.0649vw, 100vh)scale(.4614);
  }
}

.snow:nth-child(80) {
  opacity: .2137;
  animation: 13s linear -19s infinite fall-80;
  transform: translate(7.0165vw, -10px)scale(.7059);
}

@keyframes fall-80 {
  68.223% {
    transform: translate(13.0437vw, 68.223vh)scale(.7059);
  }

  to {
    transform: translate(10.0301vw, 100vh)scale(.7059);
  }
}
/*# sourceMappingURL=index.b54ce882.css.map */
