.window {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  overflow: hidden;

  filter: drop-shadow(0 0 10px white);
}

.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}

.snow:nth-child(1) {
  opacity: 0.1364;
  transform: translate(82.2313vw, -10px) scale(0.7863);
  animation: fall-1 27s -23s linear infinite;
}

@keyframes fall-1 {
  65.343% {
    transform: translate(77.7881vw, 65.343vh) scale(0.7863);
  }
  to {
    transform: translate(80.0097vw, 100vh) scale(0.7863);
  }
}

.snow:nth-child(2) {
  opacity: 0.9386;
  transform: translate(44.4761vw, -10px) scale(0.6909);
  animation: fall-2 20s -6s linear infinite;
}

@keyframes fall-2 {
  31.745% {
    transform: translate(44.9796vw, 31.745vh) scale(0.6909);
  }
  to {
    transform: translate(44.72785vw, 100vh) scale(0.6909);
  }
}

.snow:nth-child(3) {
  opacity: 0.4748;
  transform: translate(79.8665vw, -10px) scale(0.6873);
  animation: fall-3 26s -29s linear infinite;
}

@keyframes fall-3 {
  50.634% {
    transform: translate(87.2005vw, 50.634vh) scale(0.6873);
  }
  to {
    transform: translate(83.5335vw, 100vh) scale(0.6873);
  }
}

.snow:nth-child(4) {
  opacity: 0.3231;
  transform: translate(85.7352vw, -10px) scale(0.25);
  animation: fall-4 10s -21s linear infinite;
}

@keyframes fall-4 {
  52.586% {
    transform: translate(85.6265vw, 52.586vh) scale(0.25);
  }
  to {
    transform: translate(85.68085vw, 100vh) scale(0.25);
  }
}

.snow:nth-child(5) {
  opacity: 0.2596;
  transform: translate(74.9832vw, -10px) scale(0.7264);
  animation: fall-5 13s -5s linear infinite;
}

@keyframes fall-5 {
  59.552% {
    transform: translate(76.7696vw, 59.552vh) scale(0.7264);
  }
  to {
    transform: translate(75.8764vw, 100vh) scale(0.7264);
  }
}

.snow:nth-child(6) {
  opacity: 0.8287;
  transform: translate(54.9577vw, -10px) scale(0.0013);
  animation: fall-6 14s -23s linear infinite;
}

@keyframes fall-6 {
  38.23% {
    transform: translate(51.9426vw, 38.23vh) scale(0.0013);
  }
  to {
    transform: translate(53.45015vw, 100vh) scale(0.0013);
  }
}

.snow:nth-child(7) {
  opacity: 0.2203;
  transform: translate(57.2961vw, -10px) scale(0.9432);
  animation: fall-7 17s -16s linear infinite;
}

@keyframes fall-7 {
  72.724% {
    transform: translate(54.335vw, 72.724vh) scale(0.9432);
  }
  to {
    transform: translate(55.81555vw, 100vh) scale(0.9432);
  }
}

.snow:nth-child(8) {
  opacity: 0.9562;
  transform: translate(67.1384vw, -10px) scale(0.3331);
  animation: fall-8 25s -14s linear infinite;
}

@keyframes fall-8 {
  46.157% {
    transform: translate(71.64vw, 46.157vh) scale(0.3331);
  }
  to {
    transform: translate(69.3892vw, 100vh) scale(0.3331);
  }
}

.snow:nth-child(9) {
  opacity: 0.8685;
  transform: translate(28.721vw, -10px) scale(0.3167);
  animation: fall-9 19s -12s linear infinite;
}

@keyframes fall-9 {
  56.655% {
    transform: translate(31.6602vw, 56.655vh) scale(0.3167);
  }
  to {
    transform: translate(30.1906vw, 100vh) scale(0.3167);
  }
}

.snow:nth-child(10) {
  opacity: 0.6276;
  transform: translate(55.087vw, -10px) scale(0.6523);
  animation: fall-10 28s -16s linear infinite;
}

@keyframes fall-10 {
  30.816% {
    transform: translate(54.6646vw, 30.816vh) scale(0.6523);
  }
  to {
    transform: translate(54.8758vw, 100vh) scale(0.6523);
  }
}

.snow:nth-child(11) {
  opacity: 0.0696;
  transform: translate(43.0781vw, -10px) scale(0.5094);
  animation: fall-11 19s -5s linear infinite;
}

@keyframes fall-11 {
  41.466% {
    transform: translate(51.4085vw, 41.466vh) scale(0.5094);
  }
  to {
    transform: translate(47.2433vw, 100vh) scale(0.5094);
  }
}

.snow:nth-child(12) {
  opacity: 0.7283;
  transform: translate(53.3583vw, -10px) scale(0.4907);
  animation: fall-12 20s -12s linear infinite;
}

@keyframes fall-12 {
  49.828% {
    transform: translate(57.6372vw, 49.828vh) scale(0.4907);
  }
  to {
    transform: translate(55.49775vw, 100vh) scale(0.4907);
  }
}

.snow:nth-child(13) {
  opacity: 0.5806;
  transform: translate(0.9218vw, -10px) scale(0.8002);
  animation: fall-13 27s -26s linear infinite;
}

@keyframes fall-13 {
  41.57% {
    transform: translate(1.8292vw, 41.57vh) scale(0.8002);
  }
  to {
    transform: translate(1.3755vw, 100vh) scale(0.8002);
  }
}

.snow:nth-child(14) {
  opacity: 0.6996;
  transform: translate(77.4436vw, -10px) scale(0.9606);
  animation: fall-14 15s -3s linear infinite;
}

@keyframes fall-14 {
  69.225% {
    transform: translate(67.8695vw, 69.225vh) scale(0.9606);
  }
  to {
    transform: translate(72.65655vw, 100vh) scale(0.9606);
  }
}

.snow:nth-child(15) {
  opacity: 0.6286;
  transform: translate(51.5396vw, -10px) scale(0.1547);
  animation: fall-15 24s -7s linear infinite;
}

@keyframes fall-15 {
  47.05% {
    transform: translate(45.0746vw, 47.05vh) scale(0.1547);
  }
  to {
    transform: translate(48.3071vw, 100vh) scale(0.1547);
  }
}

.snow:nth-child(16) {
  opacity: 0.0077;
  transform: translate(74.9862vw, -10px) scale(0.242);
  animation: fall-16 20s -17s linear infinite;
}

@keyframes fall-16 {
  47.264% {
    transform: translate(69.739vw, 47.264vh) scale(0.242);
  }
  to {
    transform: translate(72.3626vw, 100vh) scale(0.242);
  }
}

.snow:nth-child(17) {
  opacity: 0.4839;
  transform: translate(97.9206vw, -10px) scale(0.1405);
  animation: fall-17 15s -28s linear infinite;
}

@keyframes fall-17 {
  44.106% {
    transform: translate(89.9019vw, 44.106vh) scale(0.1405);
  }
  to {
    transform: translate(93.91125vw, 100vh) scale(0.1405);
  }
}

.snow:nth-child(18) {
  opacity: 0.5348;
  transform: translate(4.0595vw, -10px) scale(0.9888);
  animation: fall-18 26s -17s linear infinite;
}

@keyframes fall-18 {
  77.746% {
    transform: translate(10.7408vw, 77.746vh) scale(0.9888);
  }
  to {
    transform: translate(7.40015vw, 100vh) scale(0.9888);
  }
}

.snow:nth-child(19) {
  opacity: 0.532;
  transform: translate(50.515vw, -10px) scale(0.2119);
  animation: fall-19 30s -7s linear infinite;
}

@keyframes fall-19 {
  33.864% {
    transform: translate(40.7821vw, 33.864vh) scale(0.2119);
  }
  to {
    transform: translate(45.64855vw, 100vh) scale(0.2119);
  }
}

.snow:nth-child(20) {
  opacity: 0.2424;
  transform: translate(22.7855vw, -10px) scale(0.7439);
  animation: fall-20 13s -24s linear infinite;
}

@keyframes fall-20 {
  63.772% {
    transform: translate(14.5357vw, 63.772vh) scale(0.7439);
  }
  to {
    transform: translate(18.6606vw, 100vh) scale(0.7439);
  }
}

.snow:nth-child(21) {
  opacity: 0.5138;
  transform: translate(22.3094vw, -10px) scale(0.9864);
  animation: fall-21 18s -17s linear infinite;
}

@keyframes fall-21 {
  64.084% {
    transform: translate(19.8423vw, 64.084vh) scale(0.9864);
  }
  to {
    transform: translate(21.07585vw, 100vh) scale(0.9864);
  }
}

.snow:nth-child(22) {
  opacity: 0.0332;
  transform: translate(67.0775vw, -10px) scale(0.2716);
  animation: fall-22 24s -16s linear infinite;
}

@keyframes fall-22 {
  54.055% {
    transform: translate(71.8874vw, 54.055vh) scale(0.2716);
  }
  to {
    transform: translate(69.48245vw, 100vh) scale(0.2716);
  }
}

.snow:nth-child(23) {
  opacity: 0.1538;
  transform: translate(52.855vw, -10px) scale(0.2065);
  animation: fall-23 22s -17s linear infinite;
}

@keyframes fall-23 {
  63.105% {
    transform: translate(56.5312vw, 63.105vh) scale(0.2065);
  }
  to {
    transform: translate(54.6931vw, 100vh) scale(0.2065);
  }
}

.snow:nth-child(24) {
  opacity: 0.027;
  transform: translate(39.1657vw, -10px) scale(0.6495);
  animation: fall-24 22s -14s linear infinite;
}

@keyframes fall-24 {
  35.992% {
    transform: translate(42.5091vw, 35.992vh) scale(0.6495);
  }
  to {
    transform: translate(40.8374vw, 100vh) scale(0.6495);
  }
}

.snow:nth-child(25) {
  opacity: 0.4479;
  transform: translate(92.5964vw, -10px) scale(0.477);
  animation: fall-25 20s -14s linear infinite;
}

@keyframes fall-25 {
  42.067% {
    transform: translate(83.6051vw, 42.067vh) scale(0.477);
  }
  to {
    transform: translate(88.10075vw, 100vh) scale(0.477);
  }
}

.snow:nth-child(26) {
  opacity: 0.0631;
  transform: translate(68.0214vw, -10px) scale(0.0277);
  animation: fall-26 10s -10s linear infinite;
}

@keyframes fall-26 {
  72.599% {
    transform: translate(72.9676vw, 72.599vh) scale(0.0277);
  }
  to {
    transform: translate(70.4945vw, 100vh) scale(0.0277);
  }
}

.snow:nth-child(27) {
  opacity: 0.5707;
  transform: translate(24.6851vw, -10px) scale(0.6735);
  animation: fall-27 22s -12s linear infinite;
}

@keyframes fall-27 {
  65.978% {
    transform: translate(27.554vw, 65.978vh) scale(0.6735);
  }
  to {
    transform: translate(26.11955vw, 100vh) scale(0.6735);
  }
}

.snow:nth-child(28) {
  opacity: 0.3657;
  transform: translate(12.4139vw, -10px) scale(0.3656);
  animation: fall-28 19s -9s linear infinite;
}

@keyframes fall-28 {
  59.857% {
    transform: translate(3.1087vw, 59.857vh) scale(0.3656);
  }
  to {
    transform: translate(7.7613vw, 100vh) scale(0.3656);
  }
}

.snow:nth-child(29) {
  opacity: 0.9101;
  transform: translate(68.525vw, -10px) scale(0.9472);
  animation: fall-29 13s -16s linear infinite;
}

@keyframes fall-29 {
  33.3% {
    transform: translate(64.9843vw, 33.3vh) scale(0.9472);
  }
  to {
    transform: translate(66.75465vw, 100vh) scale(0.9472);
  }
}

.snow:nth-child(30) {
  opacity: 0.9642;
  transform: translate(28.647vw, -10px) scale(0.988);
  animation: fall-30 28s -22s linear infinite;
}

@keyframes fall-30 {
  54.794% {
    transform: translate(26.5669vw, 54.794vh) scale(0.988);
  }
  to {
    transform: translate(27.60695vw, 100vh) scale(0.988);
  }
}

.snow:nth-child(31) {
  opacity: 0.9683;
  transform: translate(56.569vw, -10px) scale(0.3002);
  animation: fall-31 15s -18s linear infinite;
}

@keyframes fall-31 {
  41.279% {
    transform: translate(51.1001vw, 41.279vh) scale(0.3002);
  }
  to {
    transform: translate(53.83455vw, 100vh) scale(0.3002);
  }
}

.snow:nth-child(32) {
  opacity: 0.6691;
  transform: translate(84.6135vw, -10px) scale(0.1281);
  animation: fall-32 25s -15s linear infinite;
}

@keyframes fall-32 {
  66.544% {
    transform: translate(81.4651vw, 66.544vh) scale(0.1281);
  }
  to {
    transform: translate(83.0393vw, 100vh) scale(0.1281);
  }
}

.snow:nth-child(33) {
  opacity: 0.1674;
  transform: translate(78.3289vw, -10px) scale(0.5307);
  animation: fall-33 28s -15s linear infinite;
}

@keyframes fall-33 {
  42.94% {
    transform: translate(73.1029vw, 42.94vh) scale(0.5307);
  }
  to {
    transform: translate(75.7159vw, 100vh) scale(0.5307);
  }
}

.snow:nth-child(34) {
  opacity: 0.5063;
  transform: translate(46.5136vw, -10px) scale(0.8248);
  animation: fall-34 27s -18s linear infinite;
}

@keyframes fall-34 {
  73.205% {
    transform: translate(50.114vw, 73.205vh) scale(0.8248);
  }
  to {
    transform: translate(48.3138vw, 100vh) scale(0.8248);
  }
}

.snow:nth-child(35) {
  opacity: 0.6192;
  transform: translate(13.7004vw, -10px) scale(0.9279);
  animation: fall-35 20s -10s linear infinite;
}

@keyframes fall-35 {
  41.022% {
    transform: translate(8.5521vw, 41.022vh) scale(0.9279);
  }
  to {
    transform: translate(11.12625vw, 100vh) scale(0.9279);
  }
}

.snow:nth-child(36) {
  opacity: 0.9667;
  transform: translate(94.0611vw, -10px) scale(0.6602);
  animation: fall-36 10s -25s linear infinite;
}

@keyframes fall-36 {
  53.511% {
    transform: translate(100.7878vw, 53.511vh) scale(0.6602);
  }
  to {
    transform: translate(97.42445vw, 100vh) scale(0.6602);
  }
}

.snow:nth-child(37) {
  opacity: 0.9981;
  transform: translate(87.6034vw, -10px) scale(0.1878);
  animation: fall-37 23s -13s linear infinite;
}

@keyframes fall-37 {
  79.2% {
    transform: translate(93.4931vw, 79.2vh) scale(0.1878);
  }
  to {
    transform: translate(90.54825vw, 100vh) scale(0.1878);
  }
}

.snow:nth-child(38) {
  opacity: 0.9242;
  transform: translate(45.7763vw, -10px) scale(0.4504);
  animation: fall-38 30s -29s linear infinite;
}

@keyframes fall-38 {
  49.734% {
    transform: translate(36.0349vw, 49.734vh) scale(0.4504);
  }
  to {
    transform: translate(40.9056vw, 100vh) scale(0.4504);
  }
}

.snow:nth-child(39) {
  opacity: 0.0362;
  transform: translate(22.334vw, -10px) scale(0.9909);
  animation: fall-39 25s -21s linear infinite;
}

@keyframes fall-39 {
  42.814% {
    transform: translate(21.2204vw, 42.814vh) scale(0.9909);
  }
  to {
    transform: translate(21.7772vw, 100vh) scale(0.9909);
  }
}

.snow:nth-child(40) {
  opacity: 0.8963;
  transform: translate(72.7826vw, -10px) scale(0.5039);
  animation: fall-40 12s -25s linear infinite;
}

@keyframes fall-40 {
  62.162% {
    transform: translate(67.337vw, 62.162vh) scale(0.5039);
  }
  to {
    transform: translate(70.0598vw, 100vh) scale(0.5039);
  }
}

.snow:nth-child(41) {
  opacity: 0.5631;
  transform: translate(45.4899vw, -10px) scale(0.3321);
  animation: fall-41 30s -20s linear infinite;
}

@keyframes fall-41 {
  69.146% {
    transform: translate(52.8542vw, 69.146vh) scale(0.3321);
  }
  to {
    transform: translate(49.17205vw, 100vh) scale(0.3321);
  }
}

.snow:nth-child(42) {
  opacity: 0.9806;
  transform: translate(73.7428vw, -10px) scale(0.8504);
  animation: fall-42 28s -18s linear infinite;
}

@keyframes fall-42 {
  61.58% {
    transform: translate(73.4289vw, 61.58vh) scale(0.8504);
  }
  to {
    transform: translate(73.58585vw, 100vh) scale(0.8504);
  }
}

.snow:nth-child(43) {
  opacity: 0.9344;
  transform: translate(59.3926vw, -10px) scale(0.6066);
  animation: fall-43 19s -29s linear infinite;
}

@keyframes fall-43 {
  76.361% {
    transform: translate(56.0164vw, 76.361vh) scale(0.6066);
  }
  to {
    transform: translate(57.7045vw, 100vh) scale(0.6066);
  }
}

.snow:nth-child(44) {
  opacity: 0.2989;
  transform: translate(79.6133vw, -10px) scale(0.1864);
  animation: fall-44 27s -23s linear infinite;
}

@keyframes fall-44 {
  59.591% {
    transform: translate(84.4865vw, 59.591vh) scale(0.1864);
  }
  to {
    transform: translate(82.0499vw, 100vh) scale(0.1864);
  }
}

.snow:nth-child(45) {
  opacity: 0.5673;
  transform: translate(93.594vw, -10px) scale(0.4942);
  animation: fall-45 24s -13s linear infinite;
}

@keyframes fall-45 {
  75.672% {
    transform: translate(101.6675vw, 75.672vh) scale(0.4942);
  }
  to {
    transform: translate(97.63075vw, 100vh) scale(0.4942);
  }
}

.snow:nth-child(46) {
  opacity: 0.3112;
  transform: translate(64.873vw, -10px) scale(0.1594);
  animation: fall-46 17s -8s linear infinite;
}

@keyframes fall-46 {
  65.808% {
    transform: translate(66.1932vw, 65.808vh) scale(0.1594);
  }
  to {
    transform: translate(65.5331vw, 100vh) scale(0.1594);
  }
}

.snow:nth-child(47) {
  opacity: 0.3674;
  transform: translate(69.2192vw, -10px) scale(0.0137);
  animation: fall-47 24s -8s linear infinite;
}

@keyframes fall-47 {
  70.781% {
    transform: translate(77.8559vw, 70.781vh) scale(0.0137);
  }
  to {
    transform: translate(73.53755vw, 100vh) scale(0.0137);
  }
}

.snow:nth-child(48) {
  opacity: 0.0688;
  transform: translate(62.1856vw, -10px) scale(0.6748);
  animation: fall-48 23s -16s linear infinite;
}

@keyframes fall-48 {
  59.884% {
    transform: translate(67.8157vw, 59.884vh) scale(0.6748);
  }
  to {
    transform: translate(65.00065vw, 100vh) scale(0.6748);
  }
}

.snow:nth-child(49) {
  opacity: 0.4961;
  transform: translate(71.4706vw, -10px) scale(0.1354);
  animation: fall-49 14s -8s linear infinite;
}

@keyframes fall-49 {
  67.61% {
    transform: translate(65.2686vw, 67.61vh) scale(0.1354);
  }
  to {
    transform: translate(68.3696vw, 100vh) scale(0.1354);
  }
}

.snow:nth-child(50) {
  opacity: 0.238;
  transform: translate(5.4802vw, -10px) scale(0.274);
  animation: fall-50 28s -10s linear infinite;
}

@keyframes fall-50 {
  31.695% {
    transform: translate(7.6815vw, 31.695vh) scale(0.274);
  }
  to {
    transform: translate(6.58085vw, 100vh) scale(0.274);
  }
}

.snow:nth-child(51) {
  opacity: 0.461;
  transform: translate(31.906vw, -10px) scale(0.3547);
  animation: fall-51 15s -2s linear infinite;
}

@keyframes fall-51 {
  55.343% {
    transform: translate(23.1549vw, 55.343vh) scale(0.3547);
  }
  to {
    transform: translate(27.53045vw, 100vh) scale(0.3547);
  }
}

.snow:nth-child(52) {
  opacity: 0.1583;
  transform: translate(96.9088vw, -10px) scale(0.4426);
  animation: fall-52 18s -22s linear infinite;
}

@keyframes fall-52 {
  40.192% {
    transform: translate(90.7059vw, 40.192vh) scale(0.4426);
  }
  to {
    transform: translate(93.80735vw, 100vh) scale(0.4426);
  }
}

.snow:nth-child(53) {
  opacity: 0.7749;
  transform: translate(57.5278vw, -10px) scale(0.6148);
  animation: fall-53 14s -7s linear infinite;
}

@keyframes fall-53 {
  68.588% {
    transform: translate(60.7395vw, 68.588vh) scale(0.6148);
  }
  to {
    transform: translate(59.13365vw, 100vh) scale(0.6148);
  }
}

.snow:nth-child(54) {
  opacity: 0.2784;
  transform: translate(72.9829vw, -10px) scale(0.2507);
  animation: fall-54 28s -26s linear infinite;
}

@keyframes fall-54 {
  58.416% {
    transform: translate(82.0015vw, 58.416vh) scale(0.2507);
  }
  to {
    transform: translate(77.4922vw, 100vh) scale(0.2507);
  }
}

.snow:nth-child(55) {
  opacity: 0.9243;
  transform: translate(13.0774vw, -10px) scale(0.5496);
  animation: fall-55 18s -4s linear infinite;
}

@keyframes fall-55 {
  52.599% {
    transform: translate(8.0595vw, 52.599vh) scale(0.5496);
  }
  to {
    transform: translate(10.56845vw, 100vh) scale(0.5496);
  }
}

.snow:nth-child(56) {
  opacity: 0.0811;
  transform: translate(58.9802vw, -10px) scale(0.173);
  animation: fall-56 18s -29s linear infinite;
}

@keyframes fall-56 {
  41.587% {
    transform: translate(57.5694vw, 41.587vh) scale(0.173);
  }
  to {
    transform: translate(58.2748vw, 100vh) scale(0.173);
  }
}

.snow:nth-child(57) {
  opacity: 0.9797;
  transform: translate(25.0391vw, -10px) scale(0.7312);
  animation: fall-57 18s -27s linear infinite;
}

@keyframes fall-57 {
  73.038% {
    transform: translate(26.4578vw, 73.038vh) scale(0.7312);
  }
  to {
    transform: translate(25.74845vw, 100vh) scale(0.7312);
  }
}

.snow:nth-child(58) {
  opacity: 0.5929;
  transform: translate(42.327vw, -10px) scale(0.1337);
  animation: fall-58 25s -4s linear infinite;
}

@keyframes fall-58 {
  63.433% {
    transform: translate(40.6617vw, 63.433vh) scale(0.1337);
  }
  to {
    transform: translate(41.49435vw, 100vh) scale(0.1337);
  }
}

.snow:nth-child(59) {
  opacity: 0.4289;
  transform: translate(15.2247vw, -10px) scale(0.1851);
  animation: fall-59 15s -27s linear infinite;
}

@keyframes fall-59 {
  70.534% {
    transform: translate(14.3557vw, 70.534vh) scale(0.1851);
  }
  to {
    transform: translate(14.7902vw, 100vh) scale(0.1851);
  }
}

.snow:nth-child(60) {
  opacity: 0.6795;
  transform: translate(17.9368vw, -10px) scale(0.24);
  animation: fall-60 11s -30s linear infinite;
}

@keyframes fall-60 {
  38.126% {
    transform: translate(24.3168vw, 38.126vh) scale(0.24);
  }
  to {
    transform: translate(21.1268vw, 100vh) scale(0.24);
  }
}

.snow:nth-child(61) {
  opacity: 0.1039;
  transform: translate(29.7802vw, -10px) scale(0.7182);
  animation: fall-61 29s -14s linear infinite;
}

@keyframes fall-61 {
  58.6% {
    transform: translate(26.4215vw, 58.6vh) scale(0.7182);
  }
  to {
    transform: translate(28.10085vw, 100vh) scale(0.7182);
  }
}

.snow:nth-child(62) {
  opacity: 0.9957;
  transform: translate(4.0348vw, -10px) scale(0.3624);
  animation: fall-62 24s -21s linear infinite;
}

@keyframes fall-62 {
  40.429% {
    transform: translate(9.8011vw, 40.429vh) scale(0.3624);
  }
  to {
    transform: translate(6.91795vw, 100vh) scale(0.3624);
  }
}

.snow:nth-child(63) {
  opacity: 0.6313;
  transform: translate(6.556vw, -10px) scale(0.031);
  animation: fall-63 11s -6s linear infinite;
}

@keyframes fall-63 {
  44.836% {
    transform: translate(7.8627vw, 44.836vh) scale(0.031);
  }
  to {
    transform: translate(7.20935vw, 100vh) scale(0.031);
  }
}

.snow:nth-child(64) {
  opacity: 0.4424;
  transform: translate(46.3978vw, -10px) scale(0.5924);
  animation: fall-64 18s -19s linear infinite;
}

@keyframes fall-64 {
  51.924% {
    transform: translate(40.6746vw, 51.924vh) scale(0.5924);
  }
  to {
    transform: translate(43.5362vw, 100vh) scale(0.5924);
  }
}

.snow:nth-child(65) {
  opacity: 0.5703;
  transform: translate(64.2431vw, -10px) scale(0.8898);
  animation: fall-65 27s -7s linear infinite;
}

@keyframes fall-65 {
  30.355% {
    transform: translate(63.1203vw, 30.355vh) scale(0.8898);
  }
  to {
    transform: translate(63.6817vw, 100vh) scale(0.8898);
  }
}

.snow:nth-child(66) {
  opacity: 0.1838;
  transform: translate(20.3338vw, -10px) scale(0.5161);
  animation: fall-66 13s -15s linear infinite;
}

@keyframes fall-66 {
  60.11% {
    transform: translate(15.0389vw, 60.11vh) scale(0.5161);
  }
  to {
    transform: translate(17.68635vw, 100vh) scale(0.5161);
  }
}

.snow:nth-child(67) {
  opacity: 0.1145;
  transform: translate(12.9544vw, -10px) scale(0.6976);
  animation: fall-67 26s -28s linear infinite;
}

@keyframes fall-67 {
  69.34% {
    transform: translate(16.9065vw, 69.34vh) scale(0.6976);
  }
  to {
    transform: translate(14.93045vw, 100vh) scale(0.6976);
  }
}

.snow:nth-child(68) {
  opacity: 0.6725;
  transform: translate(56.8905vw, -10px) scale(0.0916);
  animation: fall-68 22s -19s linear infinite;
}

@keyframes fall-68 {
  65.687% {
    transform: translate(48.6267vw, 65.687vh) scale(0.0916);
  }
  to {
    transform: translate(52.7586vw, 100vh) scale(0.0916);
  }
}

.snow:nth-child(69) {
  opacity: 0.461;
  transform: translate(96.7856vw, -10px) scale(0.9072);
  animation: fall-69 25s -28s linear infinite;
}

@keyframes fall-69 {
  54.093% {
    transform: translate(105.4696vw, 54.093vh) scale(0.9072);
  }
  to {
    transform: translate(101.1276vw, 100vh) scale(0.9072);
  }
}

.snow:nth-child(70) {
  opacity: 0.9748;
  transform: translate(62.5139vw, -10px) scale(0.7016);
  animation: fall-70 15s -30s linear infinite;
}

@keyframes fall-70 {
  34.218% {
    transform: translate(68.6511vw, 34.218vh) scale(0.7016);
  }
  to {
    transform: translate(65.5825vw, 100vh) scale(0.7016);
  }
}

.snow:nth-child(71) {
  opacity: 0.5647;
  transform: translate(78.7795vw, -10px) scale(0.7623);
  animation: fall-71 10s -18s linear infinite;
}

@keyframes fall-71 {
  42.582% {
    transform: translate(80.8839vw, 42.582vh) scale(0.7623);
  }
  to {
    transform: translate(79.8317vw, 100vh) scale(0.7623);
  }
}

.snow:nth-child(72) {
  opacity: 0.4184;
  transform: translate(43.251vw, -10px) scale(0.2464);
  animation: fall-72 28s -30s linear infinite;
}

@keyframes fall-72 {
  33.377% {
    transform: translate(48.9489vw, 33.377vh) scale(0.2464);
  }
  to {
    transform: translate(46.09995vw, 100vh) scale(0.2464);
  }
}

.snow:nth-child(73) {
  opacity: 0.6056;
  transform: translate(23.2616vw, -10px) scale(0.4242);
  animation: fall-73 11s -4s linear infinite;
}

@keyframes fall-73 {
  39.524% {
    transform: translate(30.5329vw, 39.524vh) scale(0.4242);
  }
  to {
    transform: translate(26.89725vw, 100vh) scale(0.4242);
  }
}

.snow:nth-child(74) {
  opacity: 0.6702;
  transform: translate(29.1476vw, -10px) scale(0.2822);
  animation: fall-74 21s -13s linear infinite;
}

@keyframes fall-74 {
  79.46% {
    transform: translate(28.1258vw, 79.46vh) scale(0.2822);
  }
  to {
    transform: translate(28.6367vw, 100vh) scale(0.2822);
  }
}

.snow:nth-child(75) {
  opacity: 0.4335;
  transform: translate(21.7903vw, -10px) scale(0.9176);
  animation: fall-75 24s -10s linear infinite;
}

@keyframes fall-75 {
  41.978% {
    transform: translate(13.9585vw, 41.978vh) scale(0.9176);
  }
  to {
    transform: translate(17.8744vw, 100vh) scale(0.9176);
  }
}

.snow:nth-child(76) {
  opacity: 0.8579;
  transform: translate(99.4637vw, -10px) scale(0.0502);
  animation: fall-76 25s -20s linear infinite;
}

@keyframes fall-76 {
  48.448% {
    transform: translate(102.5922vw, 48.448vh) scale(0.0502);
  }
  to {
    transform: translate(101.02795vw, 100vh) scale(0.0502);
  }
}

.snow:nth-child(77) {
  opacity: 0.2218;
  transform: translate(65.6886vw, -10px) scale(0.9941);
  animation: fall-77 23s -16s linear infinite;
}

@keyframes fall-77 {
  49.363% {
    transform: translate(63.0789vw, 49.363vh) scale(0.9941);
  }
  to {
    transform: translate(64.38375vw, 100vh) scale(0.9941);
  }
}

.snow:nth-child(78) {
  opacity: 0.4406;
  transform: translate(6.5309vw, -10px) scale(0.5314);
  animation: fall-78 15s -22s linear infinite;
}

@keyframes fall-78 {
  73.18% {
    transform: translate(8.3066vw, 73.18vh) scale(0.5314);
  }
  to {
    transform: translate(7.41875vw, 100vh) scale(0.5314);
  }
}

.snow:nth-child(79) {
  opacity: 0.5745;
  transform: translate(55.6052vw, -10px) scale(0.4614);
  animation: fall-79 26s -14s linear infinite;
}

@keyframes fall-79 {
  64.742% {
    transform: translate(62.5246vw, 64.742vh) scale(0.4614);
  }
  to {
    transform: translate(59.0649vw, 100vh) scale(0.4614);
  }
}

.snow:nth-child(80) {
  opacity: 0.2137;
  transform: translate(7.0165vw, -10px) scale(0.7059);
  animation: fall-80 13s -19s linear infinite;
}

@keyframes fall-80 {
  68.223% {
    transform: translate(13.0437vw, 68.223vh) scale(0.7059);
  }
  to {
    transform: translate(10.0301vw, 100vh) scale(0.7059);
  }
}
